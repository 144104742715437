import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/components/common/selectitem';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MasterFormulationRecord } from '../../../module/production/model/manage-batch-record.interface';
import { GenerateId } from '../common-helper';
import { ToastrService } from 'ngx-toastr';
import { StorageConfiguration } from '../storage-setting/storage-configuration';

@Component({
  selector: 'app-batch-comments',
  templateUrl: './batch-comments.component.html',
  styleUrls: ['./batch-comments.component.sass']
})
export class BatchCommentsComponent implements OnInit {
  validform: boolean;
  LowLevel_Name: any;
  LowLevel_value: any;
  LowLevel_SelectItem: SelectItem[] = [];
  HighLevel_SelectItem: SelectItem[];
  ChildBatch_SelectItem: SelectItem[];
  modificationTypeId: any;
  Modification_Compound_info: FormGroup;
  Modification_CC_info: FormGroup;
  Modification_info: FormGroup;

  ipAddress: any;
  commentForm: FormGroup;
  sectionType: string = 'Comment';
  Comments = [];
  Comment: string;
  MasterFormulationRecord: MasterFormulationRecord;
  modificationType: string;
  modificationTypeName: string;
  modificationType_SelectItem: SelectItem[];
  stepType_SelectItem: SelectItem[];
  methodologyType_SelectItem: SelectItem[];
  component_SelectItem: SelectItem[];
  chemical_SelectItem: SelectItem[];
  commonFormvalue: any;
  displayCommentvalue = [];
  displayCommentvalue2 = [];
  displayComments: any;
  Errors: any[];

  @Input() ViewDetail: Boolean = false;
  @Input() BatchDetailForm: any;
  @Input() IsFrom: any;
  @Input() BatchStepNo: any;
  @Input() ShowStart: Boolean;
  isMasterBatch: Boolean;
  @Output() BatchCommentEmitter = new EventEmitter();

  BatchDetail: any;
  HighLevelProcess: any;
  CurrentProcessName: any;
  userInfo: any;
  viewDetail: Boolean;
  Modification_GenTime_info: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private generateId: GenerateId,
    private storageConfiguration: StorageConfiguration
  ) {

    this.commentForm = this.fb.group({
      id: [''],
      Comment_type: [''],
      Comments: ['', Validators.required],
      Comments_info: this.fb.group({
        ip_address: [''],
        Name: [''],
        By: [''],
        Date: [''],
      }),
      BatchStep: ['']
    });

    this.Modification_CC_info = this.fb.group({
      Comment_type: [''],
      Type: ['', Validators.required],
      id: [''],
      Modification_details: this.fb.group({
        id: ['', Validators.required],
        Name: [''],
      }),
      Comments: ['', Validators.required],
      Comments_info: this.fb.group({
        ip_address: [''],
        Name: [''],
        By: [''],
        Date: [''],
      })
    });


    this.Modification_Compound_info = this.fb.group({
      Comment_type: [''],
      Type: ['', Validators.required],
      id: [''],
      Comments: ['', Validators.required],
      Modification_details: this.fb.group({
        id: ['', Validators.required],
        Name: [''],
        high_level_process: this.fb.group({
          Name: ['', Validators.required],
          hierarchy: ['']
        }),
        low_level_process: this.fb.group({
          Name: [''],
          hierarchy: ['']
        }),
      }),
      Comments_info: this.fb.group({
        ip_address: [''],
        Name: [''],
        By: [''],
        Date: [''],
      })
    });

    this.Modification_GenTime_info = this.fb.group({
      Comment_type: [''],
      Type: ['', Validators.required],
      id: [''],
      Comments: ['', Validators.required],
      Comments_info: this.fb.group({
        ip_address: [''],
        Name: [''],
        By: [''],
        Date: [''],
      })
    });


    this.modificationType_SelectItem = [

      { label: 'General', value: 'General' },
      { label: 'Chemical', value: 'Chemical' },
      { label: 'Component', value: 'Component' },
      { label: 'Compounding Methodology', value: 'Compounding Methodology' },
      { label: 'Time Elapsed', value: 'Time Elapsed' },
    ];






  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    const publicIp = require('public-ip');

    publicIp.v4().then(ip => {
      this.ipAddress = ip;
    });


    // this.BatchDetail = this.BatchDetailForm.value;

    this.isMasterBatch = this.BatchDetailForm.get('Is_master_batch').value;


    if (this.isMasterBatch) {
      this.modificationType_SelectItem = [
        { label: 'Chemical', value: 'Chemical' },
        { label: 'Component', value: 'Component' },
        { label: 'Compounding Methodology', value: 'Compounding Methodology' },
        { label: 'Child Batch', value: 'Child Batch' }
      ];
    }
    this.displayCommentvalue = this.BatchDetailForm.get('Batch_comments').value;
    this.displayCommentvalue2 = this.BatchDetailForm.get('Batch_comments').value;
    if (this.IsFrom == 'BatchStart') {
      this.displayCommentvalue = this.displayCommentvalue.filter(x => x.Comment_type != 'Comment');
      this.displayCommentvalue = this.displayCommentvalue.filter(x => x.Type == 'Compounding Methodology');
      this.displayCommentvalue = this.displayCommentvalue.filter(x => x.Modification_details.high_level_process.hierarchy == this.BatchStepNo + 1);
      this.displayCommentvalue2 = this.displayCommentvalue2.filter(x => x.Comment_type == 'Comment');
      this.displayCommentvalue2 = this.displayCommentvalue2.filter(x => x.BatchStep == this.BatchStepNo + 1);
      // this.displayCommentvalue.push(this.displayCommentvalue2);
      this.displayCommentvalue = this.displayCommentvalue.concat(this.displayCommentvalue2);

    }
    this.displayComments = this.displayCommentvalue.reverse();



  }


  /** BINDING THE DROP DOWN  VALUE **/
  bindDropDown(array: any[], ddType: string): any[] {

    var dropDownItem = [];
    array.forEach(a => {
      switch (ddType) {
        case "default":
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case "level_process":
          dropDownItem.push({ label: a.Name, value: a.Hierarchical });
          break;
        case "childbatch":
          dropDownItem.push({ label: a.Lot_number, value: a.id });
          break;
      }
    });
    return dropDownItem;
  }



  addComments() {

    var type = this.sectionType;

    if (type == 'Comment') {


      this.commentForm.patchValue({
        id: this.generateId.getId(),
        Comment_type: type,
        Comments: this.Comment,
        Comments_info: {
          ip_address: this.ipAddress,
          Name: this.userInfo[0].name,
          By: this.userInfo[0].id,
          Date: new Date()
        },
        BatchStep: ''
      });
      if (this.IsFrom == 'BatchStart') {
        this.commentForm.patchValue({
          BatchStep: this.BatchStepNo + 1
        });
      }
      this.invalidFinder('comment');
      //  this.commonFormvalue = this.commentForm.value;
    } else {
      if (this.modificationType == 'Chemical' || this.modificationType == 'Component') {

        this.Modification_CC_info.patchValue({
          Type: this.modificationType,
          Modification_details: {
            id: this.modificationTypeId,
            Name: this.modificationTypeName,
          },
          id: this.generateId.getId(),
          Comment_type: type,
          Comments: this.Comment,
          Comments_info: {
            ip_address: this.ipAddress,
            Name: this.userInfo[0].name,
            By: this.userInfo[0].id,
            Date: new Date()
          }
        });
        this.invalidFinder('cc');
        //    this.commonFormvalue = this.Modification_CC_info.value;
      } else if (this.modificationType == 'Compounding Methodology') {
        this.Modification_Compound_info = this.fb.group({
          Type: this.modificationType,
          Modification_details: {
            id: this.MasterFormulationRecord.Apseptic_method.id,
            Name: this.MasterFormulationRecord.Apseptic_method.Name,
            high_level_process: {
              Name: this.modificationTypeName != null ? this.modificationTypeName : '',
              hierarchy: this.modificationTypeId != null ? this.modificationTypeId : ''
            },
            low_level_process: {
              Name: this.LowLevel_Name != null ? this.LowLevel_Name : '',
              hierarchy: this.LowLevel_value != null ? this.LowLevel_value : ''

            },
          },
          id: this.generateId.getId(),
          Comment_type: type,
          Comments: this.Comment,
          Comments_info: {
            ip_address: this.ipAddress,
            Name: this.userInfo[0].name,
            By: this.userInfo[0].id,
            Date: new Date()
          }
        });
        this.invalidFinder('compound');

      } else {
        this.Modification_GenTime_info.patchValue({
          Type: this.modificationType,
          id: this.generateId.getId(),
          Comment_type: type,
          Comments: this.Comment,
          Comments_info: {
            ip_address: this.ipAddress,
            Name: this.userInfo[0].name,
            By: this.userInfo[0].id,
            Date: new Date()
          }
        });
        this.invalidFinder('gt');
      }

    }




    // this.BatchCommentEmitter.emit(this.commonFormvalue);

    // if(this.Comment != ''){
    //   let obj ={Comment: this.Comment ,date: new Date()}
    //   this.Comments.push(obj);
    //   this.Comment = '';
    // }

  }

  ModificationOnchange() {
    // const comments = this.BatchDetailForm.get('Batch_comments').value;
    // this.displayComments = comments.reverse();
    this.MasterFormulationRecord = this.BatchDetailForm.get('Master_formulation_record').value;
    this.chemical_SelectItem = this.bindDropDown(this.MasterFormulationRecord.Chemical_inventory, 'default');
    this.component_SelectItem = this.bindDropDown(this.MasterFormulationRecord.Component_inventory, 'default');
    this.HighLevel_SelectItem = this.bindDropDown(this.MasterFormulationRecord.Apseptic_method.High_level_process, 'level_process');
    this.ChildBatch_SelectItem = this.bindDropDown(this.BatchDetailForm.get('Child_batches').value, 'childbatch');
  }

  CommentTypeChange() {


    if (this.IsFrom == 'BatchStart') {
      this.modificationType = 'Compounding Methodology';
      this.MasterFormulationRecord = this.BatchDetailForm.get('Master_formulation_record').value;
      this.HighLevel_SelectItem = this.bindDropDown(this.MasterFormulationRecord.Apseptic_method.High_level_process, 'level_process');
      this.HighLevelProcess = this.BatchDetailForm.get('Master_formulation_record.Apseptic_method.High_level_process').value;
      this.modificationTypeId = this.BatchStepNo + 1;
      this.MethodologyOnchange(this.BatchStepNo + 1);
    }

  }

  ChemicalOnchange(event) {

    var Name = this.chemical_SelectItem.filter(x => x.value === event.value);
    this.modificationTypeName = Name[0].label;
  }
  ComponentOnchange(event) {

    var Name = this.component_SelectItem.filter(x => x.value === event.value);
    this.modificationTypeName = Name[0].label;
  }

  MethodologyOnchange(event) {

    let val = 0;
    if (this.IsFrom == 'BatchStart') {
      val = event;
    } else {
      val = event.value;
    }
    var Name = this.HighLevel_SelectItem.filter(x => x.value === val);
    this.modificationTypeName = Name[0].label;
    let level_process = this.MasterFormulationRecord.Apseptic_method.High_level_process.filter(x => x.Hierarchical === val);
    this.LowLevel_SelectItem = this.bindDropDown(level_process[0].Low_level_process, 'level_process');
  }
  StepOnchange(event) {

    var Name = this.LowLevel_SelectItem.filter(x => x.value === event.value);
    this.LowLevel_Name = Name[0].label;
  }

  ChildBatchOnchange(event) {
    var Name = this.ChildBatch_SelectItem.filter(x => x.value === event.value);
    this.modificationTypeName = Name[0].label;
  }

  invalidFinder(FormType) {

    const invalid = [];
    var controls;
    if (this.Comment == '' || this.Comment == undefined) {
      invalid.push('Comment');
    }


    switch (FormType) {
      case "comment":
        // controls = this.commentForm.controls;
        this.commonFormvalue = this.commentForm.value;
        // this.validform = this.commentForm.valid
        break;
      case "cc":
        if (this.modificationType == '' || this.modificationType == undefined) {
          invalid.push('Type');
        }
        if (this.modificationType == 'Component' && (this.modificationTypeId == '' || this.modificationTypeId == undefined)) {
          invalid.push('Component Name');
        }
        if (this.modificationType == 'Chemical' && (this.modificationTypeId == '' || this.modificationTypeId == undefined)) {
          invalid.push('Chemical Name');
        }

        // controls = this.Modification_CC_info.controls;
        this.commonFormvalue = this.Modification_CC_info.value;
        // this.validform = this.Modification_CC_info.valid
        break;
      case "compound":
        if (this.modificationType == '' || this.modificationType == undefined) {
          invalid.push('Type');
        }
        // controls = this.Modification_Compound_info.controls;
        this.commonFormvalue = this.Modification_Compound_info.value;
        // this.validform = this.Modification_Compound_info.valid
        if (this.modificationTypeId == '' || this.modificationTypeId == undefined) {
          invalid.push('HIGHLEVEL STEP');
        }
        break;
      case "gt":
        if (this.modificationType == '' || this.modificationType == undefined) {
          invalid.push('Type');
        }
        // controls = this.Modification_Compound_info.controls;
        this.commonFormvalue = this.Modification_GenTime_info.value;
        // this.validform = this.Modification_Compound_info.valid
        // if (this.modificationTypeId == '' || this.modificationTypeId == undefined) {
        //   invalid.push('HIGHLEVEL STEP');
        // }
        break;
    }



    let errorField = '';
    invalid.forEach(element => {
      errorField += '<li>' + element + '</li>';
    });
    errorField = '<h2>Please Enter The Following</h2><ol class="m-3" >' + errorField + '</ol>';


    // for (invalid.length) {
    //   if (controls[name].invalid) {
    //     invalid.push(name);
    //     errorField += ' ' + name + ' ';
    //   }
    // }
    this.Errors = invalid;
    // if (this.validform && this.Errors.length == 0) {
    if (this.Errors.length == 0) {
      this.Comment = '';
      this.modificationTypeId = '';
      this.BatchCommentEmitter.emit(this.commonFormvalue);
      this.ngOnInit();
      // this.displayComments.reverse();
      // this.validform = false;
      this.sectionType = 'Comment';
      this.modificationType = '';
      this.LowLevel_value = '';
      this.LowLevel_SelectItem = [];

    } else {
      this.toastr.warning(errorField);
    }
  }
}
